import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import { gsap } from "gsap";

import { FiMenu, FiX } from "react-icons/fi";

const Header = () => {
  let smartphoneMenu = useRef(null);
  const history = useHistory();

  useEffect(() => {
    gsap.set(smartphoneMenu, { y: "-100%", autoAlpha: 0.1 });
  }, []);

  const showSmartphoneMenu = () => {
    gsap.to(smartphoneMenu, {
      duration: 0.5,
      y: "0%",
      autoAlpha: 1,
      ease: "power3.inOut",
    });
  };

  const hideSmartphoneMenu = () => {
    gsap.to(smartphoneMenu, {
      duration: 0.5,
      y: "-100%",
      autoAlpha: 0.1,
      ease: "power3.inOut",
    });
  };

  return (
    <Wrapper>
      <Main>
        <Logo
          onClick={() => {
            history.push("/");
          }}
        >
          <Logoh1>Naturopathie Lausanne</Logoh1>
        </Logo>
        <Nav>
          <StyledNavLink activeClassName="active" exact to="/">
            À propos
          </StyledNavLink>
          <StyledNavLink activeClassName="active" to="/pourquoi-consulter">
            Pourquoi consulter?
          </StyledNavLink>
          <StyledNavLink activeClassName="active" to="/rendez-vous">
            Rendez-vous / Contact
          </StyledNavLink>
        </Nav>
        <HamburgerMenu
          onClick={() => {
            showSmartphoneMenu();
          }}
        >
          <FiMenu style={{ width: "35px", height: "35px" }} />
        </HamburgerMenu>
      </Main>
      <SmartphoneMenu
        ref={(e) => {
          smartphoneMenu = e;
        }}
      >
        <XWrapper
          onClick={() => {
            hideSmartphoneMenu();
          }}
        >
          <FiX style={{ width: "30px", height: "30px" }} />
        </XWrapper>
        <LinkWrapper>
          <Link
            exact
            to="/"
            onClick={() => {
              hideSmartphoneMenu();
            }}
          >
            À propos
          </Link>
          <Link
            exact
            to="/pourquoi-consulter"
            onClick={() => {
              hideSmartphoneMenu();
            }}
          >
            Pourquoi consulter?
          </Link>
          <Link
            exact
            to="/rendez-vous"
            onClick={() => {
              hideSmartphoneMenu();
            }}
          >
            Rendez-vous / Contact
          </Link>
        </LinkWrapper>
      </SmartphoneMenu>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  z-index: 3000;
`;

const Main = styled.div`
  box-shadow: 0px 0px 10px 2px #dadada;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0px;
  height: 90px;
  position: fixed;
  width: calc(100vw - 60px);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
`;

const Logo = styled.a`
  text-decoration: none;
  position: relative;
  cursor: pointer;
`;

const Logoh1 = styled.h1`
  color: #29642f;
  position: relative;
  margin: 0px 0px 0px 0px;
  @media (max-width: 500px) {
    font-size: 6vw;
  }
  @media (max-width: 350px) {
    font-size: 5.5vw;
  }
`;

const Nav = styled.nav`
  @media (max-width: 980px) {
    display: none;
  }
`;

const HamburgerMenu = styled.nav`
  @media (min-width: 981px) {
    display: none;
  }
  padding-top: 5px;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  cursor: pointer;
  margin-left: 30px;
  padding: 5px 0px;
  text-decoration: none;
  color: black;
  border-bottom: transparent solid 3px;
  transition: 0.4s ease-in-out;
  &:hover {
    border-bottom: #29642f solid 3px;
  }
  &.active {
    border-bottom: #29642f solid 3px;
  }
`;

const SmartphoneMenu = styled.nav`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5000;
  box-shadow: 0px 0px 10px 5px grey;
  @media (min-width: 951px) {
    display: none;
  }
`;

const XWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const LinkWrapper = styled.div`
  margin: 25px 40px;
`;

const Link = styled(NavLink)`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  color: black;
  text-decoration: none;
  margin: 0px;
`;

export default Header;
