import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Wrapper>
      <Copyright>© 2021 naturopathie-lausanne.ch</Copyright>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100px;
  background-color: #29642f;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  align-items: center;
`;

const Copyright = styled.p`
  color: white;
  grid-column: 2/3;
  text-align: center;
  @media (max-width: 450px) {
    margin: 0px;
  }
`;

export default Footer;
