import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
`;

export default GlobalStyle;
