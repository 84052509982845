import React from "react";
import styled from "styled-components";

import COLORS from "../../globalStyles/constants";

const RendezVous = () => {
  return (
    <Wrapper>
      <RdvMainWrapper>
        <RdvListIntro>Services Naturopathique et Iridologique</RdvListIntro>
        <RdvDiv>
          <RdvContentTitle>
            Premier rendez-vous (Naturopathie et Iridologie)
          </RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>90 Minutes</RdvDuration>
            <RdvPrice>CHF 160.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDiv>
            <RdvDescription>
              Contrôle de vitalité incluant morphologie, iridologie et
              historique personel. Vos problèmes et votre style de vie sont
              traités. Votre nutrition et votre alimentation sont revisitées, si
              nécessaire.
            </RdvDescription>
            <RdvDescription>Grâce à cette évaluation:</RdvDescription>
          </RdvDescriptionDiv>
          <RdvDescriptionList>
            <RdvDescriptionBp>
              Vous comprendrez quel régime est le plus adapté pour votre corps.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous comprendrez comment fonctionne votre corps ainsi que les
              causes des dysfonctionnements.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous comprendrez comment répondre à votre besoin avec des
              techniques naturelles.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous aurez la clé pour retrouver et maintenir votre vitalité à son
              meilleur.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Un programme de style de vie spécifique et des recommandations,
              vous sont donnés avec un calendrier de suivi.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              De nombreux traitements sont possibles: détox, revitalisation,
              stabilisation…
            </RdvDescriptionBp>
            <RdvDescription>Seulement disponible en personne.</RdvDescription>
          </RdvDescriptionList>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>Premier rendez-vous (Naturopathie)</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>75 Minutes</RdvDuration>
            <RdvPrice>CHF 140.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDiv>
            <RdvDescription>
              Contrôle de vitalité incluant morphologie et historique personnel.
              Vos problèmes et votre style de vie sont traités. Votre nutrition
              et votre alimentation sont revisitées, si nécessaire.
            </RdvDescription>
            <RdvDescription>Grâce à cette évaluation:</RdvDescription>
          </RdvDescriptionDiv>
          <RdvDescriptionList>
            <RdvDescriptionBp>
              Vous comprendrez quel régime est le plus adapté pour votre corps.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous comprendrez comment fonctionne votre corps ainsi que les
              causes des dysfonctionnements.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous comprendrez comment répondre à votre besoin avec des
              techniques naturelles.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Vous aurez la clé pour retrouver et maintenir votre vitalité à son
              meilleur niveau.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              Un programme de style de vie spécifique et des recommandations,
              vous sont donnés avec un calendrier de suivi.
            </RdvDescriptionBp>
            <RdvDescriptionBp>
              De nombreux traitements sont possibles: détox, revitalisation,
              stabilisation…
            </RdvDescriptionBp>
            <RdvDescription>
              Disponible en personne ou en visio-consultation.
            </RdvDescription>
          </RdvDescriptionList>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>Consultation de suivi</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>60 Minutes</RdvDuration>
            <RdvPrice>CHF 120.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDivNoBp>
            <RdvDescription>
              Appliquer quotidiennement des conseils pour un retour à la santé
              et le maintenir nécessite un engagement personnel. Le changement
              de style de vie n'est pas anodin et vous concerne à 100%.
            </RdvDescription>
            <RdvDescription>
              Un accompagnement personnalisé dans le temps est recommandé. Très
              souvent, trois séances suffisent. Vous aurez gagné en autonomie
              vous permettant d'atteindre une plus grande vitalité au quotidien,
              et par là, vos objectifs.
            </RdvDescription>
            <RdvDescription>
              Dans les maladies chroniques, un suivi à plus long terme est
              nécessaire.
            </RdvDescription>
            <RdvDescription>
              Disponible en personne ou en visio-consultation.
            </RdvDescription>
          </RdvDescriptionDivNoBp>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>Conseils nutrition</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>60 Minutes</RdvDuration>
            <RdvPrice>CHF 120.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDivNoBp>
            <RdvDescription>
              Faire un point sur vos habitudes alimentaires, vos besoins en
              micronutrition.
            </RdvDescription>
            <RdvDescription>
              Disponible en personne ou en visio-consultation.
            </RdvDescription>
          </RdvDescriptionDivNoBp>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>Iridologie</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>60 Minutes</RdvDuration>
            <RdvPrice>CHF 120.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDivNoBp>
            <RdvDescription>
              L’observation des iris se fait à l’aide d’un iridoscope ou une
              loupe éclairante (méthode non invasive). A l’aide d’une carte
              irienne, comme en réflexologie plantaire, nous allons pouvoir
              mettre en relation l’observation d’un signe irien (des
              colorations, taches, anneaux, la disposition des fibres, forme de
              la pupille), avec une zone correspondant à un organe (foie,
              reins…) ou à une fonction (circulation sanguine, système nerveux).
            </RdvDescription>
            <RdvDescription>Seulement disponible en personne.</RdvDescription>
          </RdvDescriptionDivNoBp>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>Sélection Fleurs de Bach</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>60 Minutes</RdvDuration>
            <RdvPrice>CHF 120.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDivNoBp>
            <RdvDescription>
              Accompagner les émotions avec les fleurs de Bach. Une méthode
              douce et naturelle pour apaiser nos émotions. Utile pour les
              enfants comme aux parents en cas de peur, d’hypersensibilité,
              solitude, abattement, désespoir, frustration, fatigue,
              découragement…
            </RdvDescription>
            <RdvDescription>
              Disponible en personne ou en visio-consultation.
            </RdvDescription>
          </RdvDescriptionDivNoBp>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>

        <RdvDiv>
          <RdvContentTitle>EFT (Emotional Freedom Technique)</RdvContentTitle>
          <RdvDiv1>
            <RdvDuration>60 Minutes</RdvDuration>
            <RdvPrice>CHF 120.00</RdvPrice>
          </RdvDiv1>
          <RdvDescriptionDivNoBp>
            <RdvDescription>
              Une technique de libération émotionnelle simple et efficace,
              particulièrement recommandée dans le cadre du traitement d’états
              traumatiques ou anxiogènes (dépendances, phobies, stress, anxiété,
              douleurs physiques, problèmes de sommeil…).
            </RdvDescription>
            <RdvDescription>
              Disponible en personne ou en visio-consultation.
            </RdvDescription>
          </RdvDescriptionDivNoBp>
          <RdvButtonWrapper
            href="https://app.healthadvisor.ch/bookings/9f0f341239571b7378c078a8ae514de4"
            target="_blank"
          >
            <RdvButton>Prendre rendez-vous</RdvButton>
          </RdvButtonWrapper>
        </RdvDiv>
      </RdvMainWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const RdvListIntro = styled.h2`
  margin-top: 0px;
  color: ${COLORS.green};
  @media (max-width: 648px) {
    text-align: center;
  }
`;

const RdvMainWrapper = styled.div`
  margin-bottom: 50px;
`;

const RdvDiv = styled.div`
  padding: 25px 35px;
  border-radius: 20px;
  border: 2px solid #29642f;
  /* box-shadow: 0px 0px 10px 2px #dadada; */
  margin-bottom: 25px;
  @media (max-width: 648px) {
    padding: 15px 15px;
  }
`;

const RdvContentTitle = styled.h4`
  margin: 0px;
`;

const RdvDiv1 = styled.div`
  display: flex;
  font-style: italic;
  color: grey;
  padding-top: 8px;
  padding-bottom: 10px;
`;

const RdvDuration = styled.div`
  margin: 0px;
`;

const RdvPrice = styled.div`
  padding-left: 25px;
  margin: 0px;
`;

const RdvDescriptionDiv = styled.div`
  margin: 0px;
  @media (max-width: 768px) {
    font-size: 11pt;
  }
`;

const RdvDescription = styled.div`
  margin: 10px 0px 0px 0px;
  text-align: justify;
  font-size: 11pt;
`;

const RdvDescriptionList = styled.ul`
  padding-bottom: 5px;
  margin-top: 5px;
  font-size: 11pt;
`;

const RdvDescriptionBp = styled.li`
  font-size: 11pt;
`;

const RdvButtonWrapper = styled.a``;

const RdvButton = styled.button`
  background-color: #29642f;
  color: white;
  text-decoration: none;
  padding: 15px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    color: #29642f;
    outline: #29642f;
    background-color: transparent;
    transition: ease-in-out 300ms;
  }
`;

const RdvDescriptionDivNoBp = styled.div`
  margin-bottom: 25px;
`;

export default RendezVous;
