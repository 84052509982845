import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import COLORS from "../globalStyles/constants";

const Services = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Main>
        <Title>Témoignages</Title>
        <TemoignageDiv>
          <TemoignageName>
            Céline, 46 ans: Problèmes de poids et de stress
          </TemoignageName>
          <TemoignageBody>
            « Sophie m’a donné un nouvel élan pour gérer le grand bouleversement
            de l’expatriation qui a entraîné une prise de poids et des problèmes
            de sommeil et de respiration. Ses conseils alimentaires et plantes
            m’ont également permis de stopper les crises d’arthrose dont je
            souffrais depuis plusieurs années. Un grand merci. »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>
            Murielle, 33 ans: Problèmes d’anxiété, sommeil et insomnie
          </TemoignageName>
          <TemoignageBody>
            « Il y a maintenant deux semaines que j’ai coupé le gluten et les
            produits laitiers, ainsi qu’éliminé la viande au souper et j’ai
            recommencé à dormir. Et ce n’est pas tout. Le plus beau dans tout
            ça, c’est que je ne me sens plus anxieuse. J’ai toujours cru que
            c’était mon anxiété qui m’empêchait de dormir, mais il semblerait
            que ce serait plutôt le manque de sommeil qui causerait mon anxiété.
            Ça ressemble un peu à une pub miracle, mon témoignage, mais, bref,
            je suis agréablement surprise de ces résultats. Je te remercie de
            tes précieux conseils ».
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>
            Famille 2 adultes – 3 enfants : soutien / stress, problèmes de
            digestion, cauchemars, surpoids / Consultation bien être, changement
            d’hygiène de vie
          </TemoignageName>
          <TemoignageBody>
            « Au printemps 2015, nous avons rencontré la Naturopathie et Sophie.
            Elle a effectué un bilan personnalisé de chacun des membres de la
            famille, 2×40 ans, 4, 7 et 10 ans. Elle a été très à l’écoute des
            enfants, chacun avec ses soucis. De même, elle a pris en compte les
            besoins et attentes de mon époux et moi même. Nous avons décidé,
            ensemble, de prendre un grand virage et de suivre ses nombreux
            conseils. Toute la famille a changé ses habitudes et le résultat est
            incroyable ! Nous sommes tous les 5 convaincus que nous n’aurions pu
            surmonter ces deux dernières années sans ce changement radical (deux
            déménagements en Europe : deux nouvelles écoles, deux nouvelles
            langues pour les enfants, de nouveau une vie sociale a reconstruire,
            du stress au travail etc.).
          </TemoignageBody>
          <TemoignageBody>
            Même si la distance nous empêche de faire un suivi régulier, Sophie
            est dans notre vie au quotidien, les enfants citent régulièrement
            ses conseils, je souris lorsque je décide de suivre ses suggestions
            en préparant les repas…
          </TemoignageBody>
          <TemoignageBody>
            Sophie nous a fait gagner en vitalité, elle nous a permis de
            préserver notre santé, de retrouver le plaisir de manger et digérer,
            de gérer les difficultés de la vie… Merci ! »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>Astrid, 29 ans: Stress et fatigue</TemoignageName>
          <TemoignageBody>
            « Bonjour, cette année j’ai testé la naturopathie. Cela faisait
            longtemps que j’avais envie d’essayer une médecine alternative et
            globale. Cela faisait plusieurs mois que j’étais particulièrement
            stressée et fatiguée et cette expérience m’a beaucoup apporté.
          </TemoignageBody>
          <TemoignageBody>
            J’ai rencontré Sophie Tranchida grâce à un ami et j’ai pris
            rendez-vous quelques semaines après pour essayer. La naturopathie
            m’a beaucoup aidé sur plusieurs points, j’ai pu mettre le doigt sur
            certaines mauvaises habitudes alimentaires qui ne me conviennent pas
            et j’ai fait le lien entre plusieurs symptômes ! Cette médecine est
            complète et ne s’occupe pas seulement du corps mais aussi de l’état
            psycho-émotionnel. Dans mon cas, c’est tout à fait cela, je «
            somatise », c’est-à-dire que j’ai mal à certains endroits parce que
            des choses me contrarient. Prendre de nouvelles habitudes m’a aidé à
            me sentir mieux et ces deux séances m’ont donné de nouvelles
            habitudes alimentaires, médicales, fleurales. J’ai également
            commencé à faire de la méditation et a changer mon rythme de
            sommeil.
          </TemoignageBody>
          <TemoignageBody>
            Tous ces conseils me guide dans ma vie aujourd’hui et même si je
            n’applique pas tous les jours chacune de mes résolutions, je connais
            maintenant beaucoup de techniques et peux à chaque instant mieux
            adapter ma façon de vivre à mon état physiologique et psychologique.
            Merci Sophie pour ces aides de vies ! »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>Corinne, 39 ans: Douleurs et fatigue</TemoignageName>
          <TemoignageBody>
            « Je suis une adepte des soins au naturel quand il est possible de
            traiter et de soutenir le corps avec douceur en suivant son
            rythme.La découverte de la naturopathie avec Sophie Tranchida m’a
            permis de prendre en considération, avec plus d’attention, mon
            corps, sa façon de fonctionner et ses souffrances. L’écoute de soi
            passant essentiellement par l’écoute de son corps puis de sa psyché,
            la naturopathie est une clé de lecture fondamentale et nécessaire
            dans la mesure où elle procède en douceur usant de méthodes
            naturelles, douces et efficaces sur un plus long terme que la
            médecine traditionnelle, complémentaire à celle-ci également. Le
            corps en ressort moins stressé et traité avec plus de respect.
          </TemoignageBody>
          <TemoignageBody>
            Je recommande donc fortement cette approche délicate et réellement
            efficace qui dure dans le temps, n’ayant pas un effet placebo comme
            nombre de gens semble le croire plus par méconnaissance ou manque
            d’information que par volonté de tenter un traitement qui n’est pas
            à proprement parler médical et pourtant clairement bon pour la
            santé. »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>
            Fabienne, 53 ans: Troubles articulaires et perte de poids
          </TemoignageName>
          <TemoignageBody>
            « A Noël 2015 je n’arrivais presque plus à marcher et j’avais mal
            aux jambes. Mon rendez-­vous chez le médecin n’était pas possible
            avant début janvier 2016. J’étais complètement désespérée. Je me
            suis alors tournée vers la médecine naturelle. J’ai cherché sur
            Internet sur Zurich une naturopathe francophone et suis tombée sur
            la page de Sophie Bucquoy Tranchida.
          </TemoignageBody>
          <TemoignageBody>
            Suite à ma première consultation Sophie m’a recommandé de mettre en
            place une diète detox et une cure de revitalisation dans le but
            d’abord d’éliminer l’inflammation, puis de perdre du poids. En
            janvier le diagnostic du médecin me confirmait une fissure du
            ménisque à la jambe droite accompagné d’un début d’arthrose. Il m’a
            prescrit des séances de physiothérapie et de perdre du poids. Donc
            en avant pour les séances de physiothérapie pour mes jambes,
            changement de nourriture et sport! Au mois de février j’arrivais de
            nouveau à marcher presque normalement. Suite à la troisième
            consultation au mois de mars, j’arrivais même à courir et j’avais
            déjà perdu 5kg. De 85kg j’étais passée à 80kg. Au mois de juillet,
            après cinq consultation, je pesais 75kg. J’avais perdu environ 10kg
            en 6 mois! Et je continue sur ma lancée.
          </TemoignageBody>
          <TemoignageBody>
            Bien sûr que c’était et est encore difficile, mais je n’avais pas du
            tout envie de finir à 53 ans dans un fauteuil roulant. Avec beaucoup
            de persévérance et de ténacité je me suis sentie complètement
            transformée. Tout est question d’équilibre et quelquefois il est
            aussi permis de se laisser aller. Le principal est de garder sa
            motivation et de prendre le temps d’arriver au but final. Grâces aux
            encouragements et conseils de Sophie je me sens aujourd’hui beaucoup
            mieux dans mon corps aussi bien physiquement que mentalement. »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>
            Valérie, 36 ans: Sélection de fleurs de Bach et revitalisation
          </TemoignageName>
          <TemoignageBody>
            « J’ai consulté Sophie pendant une période de transition
            professionnelle. Ses conseils et les mesures simples mises en place
            à la suite de nos entretiens ont été précieux et m’ont permis de
            retrouver l’énergie et la sérénité dont j’avais besoin pour vivre au
            mieux cette période. »
          </TemoignageBody>
        </TemoignageDiv>
        <TemoignageDiv>
          <TemoignageName>
            Murielle, 33 ans: Problématique de sommeil insomnie et anxiété
          </TemoignageName>
          <TemoignageBody>
            « Il y a maintenant deux semaines que j’ai coupé le gluten et les
            produits laitiers, ainsi qu’éliminé la viande au souper et j’ai
            recommencé à dormir. Et ce n’est pas tout. Le plus beau dans tout
            ça, c’est que je ne me sens plus anxieuse. J’ai toujours cru que
            c’était mon anxiété qui m’empêchait de dormir, mais il semblerait
            que ce serait plutôt le manque de sommeil qui causerait mon anxiété.
            Ça ressemble un peu à une pub miracle, mon témoignage, mais, bref,
            je suis agréablement surprise de ces résultats. Je te remercie de
            tes précieux conseils ».
          </TemoignageBody>
        </TemoignageDiv>
        <BackToMainPageWrapper>
          <BackToMainPageA
            onClick={() => {
              history.push("/");
            }}
          >
            <BackToMainPageButton>
              Retour à la page d'accueil
            </BackToMainPageButton>
          </BackToMainPageA>
        </BackToMainPageWrapper>
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 190px);
  display: flex;
  justify-content: center;
  padding: 140px 50px 50px 50px;
  @media (max-width: 400px) {
    padding: 140px 25px 25px 25px;
  }
`;

const Main = styled.div`
  max-width: 1250px;
  width: 100%;
`;

const Title = styled.h2`
  color: ${COLORS.green};
  margin-top: 0px;
  margin-bottom: 30px;
`;

const TemoignageDiv = styled.div`
  margin-bottom: 60px;
`;

const TemoignageName = styled.h3`
  text-indent: 40px;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    font-size: 10pt;
  }
`;

const TemoignageBody = styled.p`
  text-align: justify;
  text-indent: 40px;
  @media (max-width: 500px) {
    font-size: 10pt;
  }
`;

const BackToMainPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const BackToMainPageA = styled.div`
  text-decoration: none;
  color: white;
`;

const BackToMainPageButton = styled.button`
  padding: 15px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #29642f;
  color: white;
  position: relative;
  width: 250px;
  text-align: center;
  border: 2px solid #29642f;
  transition: ease-in-out 300ms;
  &:hover {
    background-color: transparent;
    color: #29642f;
  }
`;

export default Services;
