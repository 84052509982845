import React from "react";
import styled from "styled-components";

import RendezVous from "./rendezVous";
import ContactInfo from "./contactInfo";

const RendezVousPage = () => {
  return (
    <>
      <Wrapper>
        <Text>
          **LES CONSULTATIONS EN CABINET SE FONT EXCLUSIVEMENT LE SAMEDI MATIN.
          PAS D’IRIDOLOGIE PAR TÉLÉCONSULTATION.**
        </Text>
        <Main>
          <RendezVous />
          <ContactInfo />
        </Main>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 140px 50px 50px 50px;
  @media (max-width: 400px) {
    padding: 140px 25px 25px 25px;
  }
`;

const Text = styled.div`
  text-align: center;
  margin: 0px 0px 40px 0px;
  font-weight: 600;

  @media (max-width: 1000px) {
    font-size: 11pt;
  }
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-column-gap: 50px;
  max-width: 1500px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }
`;

export default RendezVousPage;
