import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Homepage = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <FirstSection>
        <BigTitle>
          Santé, <br />
          Bien-être <br />
          et vitalité <br />
          au quotidien!
        </BigTitle>
        <BackgroundImg
          src="https://res.cloudinary.com/du3e9jj68/image/upload/v1610215066/backgroundImage_c0ykwr.jpg"
          srcset={`https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_256/backgroundImage_c0ykwr.jpg 256w,
            https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_512/backgroundImage_c0ykwr.jpg 512w,
            https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_768/backgroundImage_c0ykwr.jpg 768w,
            https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_1024/backgroundImage_c0ykwr.jpg 1024w,
            https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_1280/backgroundImage_c0ykwr.jpg 1280w
            https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_100,w_1500/backgroundImage_c0ykwr.jpg 1500w`}
          alt="Background photo"
        />
      </FirstSection>

      <Body>
        <DefinitionNaturopathie>
          La naturopathie est une médecine traditionnelle européenne qui vise à
          promouvoir un bien-être optimal par le recours à des moyens naturels
          reconnus pour leur efficacité et leur innocuité.
        </DefinitionNaturopathie>

        <GridWrapper>
          <GridItem>
            <GridDiv>
              <GridTitle>Assureurs partenaires</GridTitle>
              <GridContent>
                Membre A de l'APTN practicien en thérapies naturelles.
              </GridContent>
              <GridContent>
                Avant tout traitement chez un thérapeute, le Patient doit
                préalablement s'informer auprès de son assureur si la thérapie
                et le thérapeute sont remboursés par son assurance
                complémentaire.
              </GridContent>
            </GridDiv>
            <GridButton
              onClick={() => {
                history.push("/assurances");
                console.log("assurances");
              }}
            >
              Voir tous les partenaires
            </GridButton>
          </GridItem>

          <GridItem>
            <GridDiv>
              <GridTitle>Témoignages</GridTitle>
              <GridContent>
                Voir les témoignages de clients précédents avec différents
                symptômes et traitements.
              </GridContent>
            </GridDiv>
            <GridButton
              onClick={() => {
                history.push("/temoignages");
              }}
            >
              En savoir plus
            </GridButton>
          </GridItem>

          <GridItem>
            <GridDiv>
              <GridTitle>Rendez-vous</GridTitle>
              <GridContent>
                Les rendez-vous physiques au cabinet (exclusivement le samedi):
              </GridContent>
              <GridContent>
                44 avenue Général Guisan
                <br />
                1009, Pully
              </GridContent>
              <GridContent>
                Les rendez-vous peuvent également se faire en téléconsultation.
              </GridContent>
            </GridDiv>
            <GridButton
              onClick={() => {
                history.push("/rendez-vous");
              }}
            >
              Prendre rendez-vous
            </GridButton>
          </GridItem>
        </GridWrapper>

        <SophieBioWrapper>
          <SophieBioImg
            src="https://res.cloudinary.com/du3e9jj68/image/upload/f_auto,q_70,w_500/portrait_pwnpg2.jpg"
            alt="Sophie Tranchida Portait"
          />
          <SophieBioBodyMainWrapper>
            <SophieBioTitle>Plus sur Sophie Bucquoy-Tranchida</SophieBioTitle>
            <SophieBioBodyWrapper>
              <SophieBioBody>
                Je suis Naturopathe, Iridologue, Conseillère Phytothérapie et
                Aromathérapie. Reconnue Membre A de l’APTN Suisse – Association
                des Praticiens en Thérapies Naturelles.
              </SophieBioBody>
              <SophieBioBody>
                De formation scientifique (Doctorat en Biochimie, Master en
                Biologie de la cellule normale et pathologique), les domaines de
                la santé et du bien-être ont toujours été la ligne directrice de
                mon expérience professionnelle. J’ai été enseignant-chercheur
                puis ARC attachée de recherche clinique en cardiologie avant de
                devenir maman de deux enfants. Passionnée par les végétaux, je
                suis devenue fleuriste décoratrice, en parallèle à l’éducation
                de mes enfants. Puis j’ai développé mes connaissances en
                phytothérapie et aromathérapie (DU de conseil et information en
                phytothérapie et aromathérapie de Bobigny Paris XIII obtenu en
                2010). Depuis 2014 je suis certifiée Praticienne de Santé
                Naturopathe par l’Institut Supérieur de Naturopathie (ISUPNAT),
                école agréée par la Fédération Française de Naturopathie (FENA).
                Cette formation intensive, selon l’enseignement d’Alain
                Rousseaux, comporte des cours d’anatomie-physiologie, de
                biologie de synthèse et de terrain, diététique, bionutrition et
                cuisine saine, micro-nutrition, phytologie, aromatologie,
                iridologie, gestion du stress et du sommeil, exercices
                physiques, psychologie…etc.
              </SophieBioBody>
              <SophieBioBody>
                Mon parcours de vie m’a permis d'apprécier le bienfait de la
                nature sur notre état d’être, par l’usage des végétaux en
                médicinal, en décoratif, et par l’observation des lois qu’elle
                nous dicte. C’est la combinaison des différentes approches
                scientifiques et thérapeutiques qui me permet aujourd’hui
                d’accompagner les personnes voulant maintenir ou retrouver une
                bonne santé et vitalité, que ce soit pour une perte de poids
                durable, pour améliorer des états dépressifs, ou soulager des
                maladies chroniques, inflammatoires. Tout cela par des moyens
                naturels tels que l’alimentation, l’hygiène de vie, les plantes,
                des massages.
              </SophieBioBody>
              <SophieBioBody>
                Enfin, en parallèle aux consultations de naturopathie,
                j’organise des conférences sur l’usage des plantes médicinales,
                des ateliers de créations végétales dans un objectif de
                bien-être, ainsi que des ateliers pratiques pour l’acquisition
                de techniques simples anti-stress.
              </SophieBioBody>
            </SophieBioBodyWrapper>
          </SophieBioBodyMainWrapper>
        </SophieBioWrapper>
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  overflow-x: hidden;
  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const BigTitle = styled.h1`
  margin: 0px auto;
  text-transform: uppercase;
  position: relative;
  font-weight: 900;
  font-size: 3vw;
  top: 45px;
  z-index: -1;
  @media (max-width: 2100px) {
    font-size: 45pt;
    right: -15px;
  }
  @media (max-width: 1900px) {
    font-size: 40pt;
    right: -15px;
  }
  @media (max-width: 1650px) {
    font-size: 35pt;
    right: -15px;
  }
  @media (max-width: 1439px) {
    font-size: 30pt;
    right: -15px;
  }
  @media (max-width: 1200px) {
    top: 55px;
    font-size: 28pt;
  }
  @media (max-width: 1100px) {
    font-size: 25pt;
  }
  @media (max-width: 950px) {
    text-align: center;
    display: none;
  }
`;

const BackgroundImg = styled.img`
  border-bottom-left-radius: 250px;
  max-width: 60vw;
  object-position: bottom;
  object-fit: cover;
  @media (max-width: 950px) {
    max-width: 100vw;
    border-bottom-left-radius: 0px;
  }
  @media (min-width: 2000px) {
    max-height: 44vh;
    max-width: 65vw;
    width: 65vw;
  }
`;

const Body = styled.div`
  padding: 0px 110px 75px 110px;
  max-width: 1250px;
  margin: 0px auto;
  @media (max-width: 1000px) {
    padding: 0px 100px 75px 100px;
  }
  @media (max-width: 920px) {
    padding: 0px 75px 75px 75px;
  }
  @media (max-width: 870px) {
    padding: 0px 50px 50px 50px;
  }
  @media (max-width: 460px) {
    padding: 0px 25px 25px 25px;
  }
`;

const DefinitionNaturopathie = styled.h3`
  text-align: center;
  padding: 125px 0px 115px 0px;
  max-width: 1250px;
  margin: 0px auto;
  @media (max-width: 1150px) {
    padding: 100px 0px 90px 0px;
  }
  @media (max-width: 900px) {
    padding: 75px 0px 65px 0px;
  }
  @media (max-width: 500px) {
    padding: 50px 0px 40px 0px;
    font-size: 12pt;
  }
  @media (max-width: 340px) {
    padding: 50px 0px 40px 0px;
    font-size: 11pt;
  }
`;

const GridWrapper = styled.div`
  padding-bottom: 125px;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 300px;
  justify-content: center;
  @media (max-width: 1150px) {
    padding: 0px 0px 90px 0px;
  }
  @media (max-width: 900px) {
    padding: 0px 0px 65px 0px;
  }
  @media (max-width: 820px) {
    padding: 0px 0px 40px 0px;
  }
  @media (max-width: 340px) {
    padding: 0px 0px 30px 0px;
  }
`;

const GridItem = styled.div`
  width: 300px;
  height: 300px;
  box-shadow: 0px 0px 10px 2px #dadada;
  text-align: center;
  padding: 0px 10px 10px 10px;
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 410px) {
    height: inherit;
    margin: 10px 0px;
  }
`;

const GridDiv = styled.div``;

const GridTitle = styled.h3`
  padding-top: 3px;
  color: #29642f;
`;

const GridContent = styled.p`
  padding: 0px 10px 0px 10px;
  font-weight: 400;
  font-size: 10pt;
`;

const GridButton = styled.button`
  padding: 15px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #29642f;
  color: white;
  width: 100%;
  border: 2px solid #29642f;
  transition: ease-in-out 300ms;
  &:hover {
    background-color: transparent;
    color: #29642f;
  }
`;

const SophieBioWrapper = styled.div`
  /* display: flex;
  padding-bottom: 105px; */
`;

const SophieBioBodyMainWrapper = styled.div``;

const SophieBioImg = styled.img`
  max-height: 375px;
  height: auto;
  border-top-left-radius: 75px;
  float: left;
  margin-right: 40px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SophieBioBodyWrapper = styled.div`
  height: auto;
`;

const SophieBioTitle = styled.h2`
  color: #29642f;
  text-transform: uppercase;
  margin-top: 0px;
  text-align: left;
  @media (max-width: 1000px) {
    text-align: center;
  }
`;

const SophieBioBody = styled.p`
  text-align: justify;
  text-indent: 40px;
  @media (max-width: 500px) {
    font-size: 11pt;
  }
`;

export default Homepage;
