import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Assurances = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Main>
        <Intro>
          <TexteIntro>
            Sophie Bucquoy Tranchida est membre A de l'APTN practicien en
            thérapies naturelles.
          </TexteIntro>
        </Intro>
        <Body>
          <BodySecondary>
            <BodyMainTitle>
              Les Assurances caisses maladies complémentaires reconnaissant les
              Membres-A de l'
              <AStyled href="http://www.aptn.ch/index.php/assurances/liste">
                APTN
              </AStyled>{" "}
              sont :
            </BodyMainTitle>
            <ListContainer>
              <ListItem>
                <A href="http://www.groupemutuel.ch">Groupe Mutuel</A>
              </ListItem>
              <ListItem>
                <ANoRef>AVENIR Assurance Maladie SA</ANoRef>{" "}
                (Avenir-CMBB-St.Moritz)
              </ListItem>
              <ListItem>
                <ANoRef>PHILOS Assurance Maladie SA</ANoRef>{" "}
                (Philos-Avantis-Fonction publique-Troistorrents-Panorama-EOS)
              </ListItem>
              <ListItem>
                <ANoRef>EASY SANA Assurance Maladie SA</ANoRef> (Caisse
                vaudoise-Hermes-Easysana)
              </ListItem>
              <ListItem>
                <ANoRef>MUTUEL Assurance Maladie SA</ANoRef>{" "}
                (Mutuel-Universa-Natura)
              </ListItem>
              <ListItem>
                <A href="http://www.assura.ch">Assura</A> (Natura et Natura R3)
              </ListItem>
              <ListItem>
                <A href="http://www.egk.ch">EGK</A>
              </ListItem>
            </ListContainer>
          </BodySecondary>
          <BodyTexte>
            Ces caisses maladie complémentaires reconnaissent les membres de
            l'association APTN selon leur liste individuelle de type de
            thérapies, ainsi que selon le contrat individuel des clients et
            clientes qui ont recourt aux thérapeutes en Thérapie Complémentaire
            et en Médecine Alternative.
          </BodyTexte>
          <BodyTexte>
            <ANoRef>Avant tout traitement chez un thérapeute :</ANoRef> Le
            Patient doit préalablement s'informer auprès de son assureur si la
            thérapie et le thérapeute sont remboursés par son assurance
            complémentaire.
          </BodyTexte>
          <BodyTexte>
            Certaines caisses non-mentionnées ont des conventions particulières
            entre leurs clients et elles.
          </BodyTexte>
        </Body>
        <BackToMainPageWrapper>
          <BackToMainPageA
            onClick={() => {
              history.push("/");
            }}
          >
            <BackToMainPageButton>
              Retour à la page d'accueil
            </BackToMainPageButton>
          </BackToMainPageA>
        </BackToMainPageWrapper>
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 100px);
`;

const Main = styled.div`
  padding: 140px 100px 75px 100px;
  @media (max-width: 900px) {
    padding: 140px 50px 50px 50px;
  }
`;

const Intro = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;

const TexteIntro = styled.h3`
  text-decoration: none;
  text-align: center;
`;

const A = styled.a`
  text-decoration: none;
  color: black;
  font-style: italic;
  font-weight: 600;
`;

const AStyled = styled.a`
  color: black;
`;

const ANoRef = styled.span`
  font-style: italic;
  font-weight: 600;
`;

const Body = styled.div`
  max-width: 1200px;
  margin: 0px auto 50px auto;
`;

const BodySecondary = styled.div`
  padding-bottom: 15px;
`;

const BodyMainTitle = styled.p``;

const ListContainer = styled.ul`
  list-style-type: none;
`;

const ListItem = styled.li``;

const BodyTexte = styled.p`
  text-align: justify;
`;

const BackToMainPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const BackToMainPageA = styled.div`
  text-decoration: none;
  color: white;
`;

const BackToMainPageButton = styled.button`
  padding: 15px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #29642f;
  color: white;
  position: relative;
  width: 250px;
  text-align: center;
  border: 2px solid #29642f;
  transition: ease-in-out 300ms;
  &:hover {
    background-color: transparent;
    color: #29642f;
  }
`;

export default Assurances;
