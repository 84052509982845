import React from "react";
import styled from "styled-components";

import COLORS from "../globalStyles/constants";

const PourquoiConsulter = () => {
  return (
    <>
      <Wrapper>
        <Main>
          <Section>
            <Title>Pourquoi consulter en naturopathie ?</Title>
            <MainList>
              <MainListElement>
                Pour la prévention : vous êtes en bonne santé, et vous voulez le
                rester !
              </MainListElement>
              <SecondList>
                <SecondListElement>
                  Une visite de bilan de vitalité permettra de mieux connaître
                  votre terrain, les forces et faiblesses de votre constitution,
                  et d’anticiper des éventuels désordres par un programme
                  d’hygiène de vie adapté.
                </SecondListElement>
                <SecondListElement>
                  Des actions ponctuelles appliquées en cabinet vous permettront
                  d’avancer sereinement dans votre vie : consultation en
                  nutrition, gestion des émotions, de massage, de pratique
                  d’exercices respiratoires et de relaxation, de Reiki.
                </SecondListElement>
              </SecondList>
              <MainListElement>
                Pour régler des problèmes chroniques : en utilisant des
                solutions naturelles !
              </MainListElement>
              <SecondList>
                <SecondListElement>
                  Agir contre des dérèglements chroniques : allergies, problèmes
                  inflammatoires, ORL, circulatoires, articulaires, migraines,
                  fatigue psychique…
                </SecondListElement>
                <SecondListElement>
                  Compléter les traitements allopathiques : hypertension,
                  hypercholestérolémie, diabète, pathologies auto-immunes,
                  etc...
                </SecondListElement>
                <SecondListElement>
                  Renforcer votre système immunitaire, améliorer votre
                  digestion, lutter contre la fatigue, retrouver un sommeil de
                  qualité, revenir à votre poids idéal, mieux gérer votre vie de
                  femme (grossesse, cycles, ménopause), optimiser vos
                  performances sportives ou intellectuelles, préparer un examen,
                  un concours…
                </SecondListElement>
              </SecondList>
            </MainList>
            <TextBody>
              La consultation est un moment privilégié pour faire le « tour de
              soi » : prendre le temps, prendre du recul, prendre
              conscience. Basée sur un échange, sur l’écoute et le
              questionnement approfondi du praticien, elle est l’occasion
              d’apprendre, de découvrir, de s’interroger et de décider de vivre
              autrement.
            </TextBody>
          </Section>
          <SectionLast>
            <Title>À qui s'adresse la naturopathie ?</Title>
            <TextBody>
              <Stronger>
                La naturopathie s’adresse à tous les âges de la vie, des plus
                jeunes aux plus âgés
              </Stronger>{" "}
              : homme, femme, enfants, mère au foyer, employé, cadre supérieur,
              grand-parents, seniors …
            </TextBody>
            <TextBody>
              <Stronger>
                Les problématiques et souhaits d’accompagnements les plus
                rencontrés 
              </Stronger>
              :
            </TextBody>
            <SecondList>
              <SecondListElement>
                <Strong>Pour les enfants</Strong> : poids, sommeil agité,
                cauchemars, peurs, anxiété, irritation de peau, irruptions
                cutanées, difficultés de digestion, intolérance, allergies, ORL,
                hyperactivité, perte de concentration…
              </SecondListElement>
              <SecondListElement>
                <Strong>Pour les femmes</Strong> : absence de règles, cycles
                rapprochés, prise ou perte de poids, syndrome prémenstruel,
                troubles digestifs, ballonnements, fatigue, coup de pompes,
                insomnies, préparer sa grossesse, detox, fertilité,
                accompagnement d’hypo/hyperthyroïdie, hypercholestérol,
                désordres chroniques, circulation sanguine, migraine, ménopause,
                libido, accompagnement de changements professionnel ou
                personnel, énergie, gestion du stress, des émotions…
              </SecondListElement>
              <SecondListElement>
                <Strong>Pour les hommes</Strong> : fatigue chronique, gestion
                du poids, prostate, remontées acides, irritations intestinales,
                performance…
              </SecondListElement>
              <SecondListElement>
                <Strong>Pour les seniors</Strong> : inflammation, problèmes
                articulaires, mémoire, sommeil perturbé non réparateur…
              </SecondListElement>
            </SecondList>
            <TextBody>
              L’hygiène de vie apporte des solutions concrètes.
            </TextBody>
            <TextBody>
              Les techniques naturelles utilisées permettent de faire le plein
              d’énergie, de renforcer votre système immunitaire, d’aider
              l’organisme à sortir de désordres chroniques, inflammatoires,
              d’accompagner des traitements médicaux.
            </TextBody>
            <TextBody>
              <Stronger>
                La naturopathie va permettre de renforcer votre « terrain » et
                d’aider l’organisme à mieux s’autoréguler.
              </Stronger>
            </TextBody>
            <TextBody>
              Pour les pathologies plus lourdes – hypertension,
              hypercholestérolémie, diabète, pathologies auto-immunes, etc…, le
              naturopathe peut vous accompagner en complément de votre
              traitement médical.
            </TextBody>
          </SectionLast>
          <SectionLast>
            <Title>Les techniques, outils et remèdes les plus utilisés</Title>
            <TextBody>
              Les techniques, outils et remèdes les plus utilisés:
            </TextBody>
            <SecondList>
              <SecondListElement>
                <Strong>Iridologie</Strong> : observation de la partie colorée
                de l'oeil, permettant de repérer des signes iriens (colorations,
                tâches, anneaux, disposition des fibres, lacunes, forme de la
                pupille...) révélateurs de terrain et d'éventuels
                dysfonctionnements fonctionnels, des systèmes nerveux,
                métabolique & émotionnel.
              </SecondListElement>
              <SecondListElement>
                <Strong>EFT</Strong> : pratique de « tapping » se rapprochant de
                l’acupuncture sauf que ce sont les doigts et non les aiguilles
                qui stimulent, afin de libérer ou réactiver le flux énergétique
                interne, rééquilibrant le corps et l’esprit, amenant à un
                apaisement et une résolution d’angoisses ou de peurs.
              </SecondListElement>
              <SecondListElement>
                <Strong>Respiration</Strong> : Cohérence cardiaque anti-stress,
                apaisante.
              </SecondListElement>
              <SecondListElement>
                <Strong>Alimentation</Strong> : équilibrage, régime dissocié,
                monodiète, cure de vitalité, cure détox.
              </SecondListElement>
              <SecondListElement>
                <Strong>Mise en mouvement</Strong> : recommandation de sports
                adaptés, massages, hydrologie.
              </SecondListElement>
              <SecondListElement>
                <Strong>Plantes médicinales</Strong> : tisanes, huile
                essentielles (aromathérapie), EPS (extraits de Plantes fraîches
                Standardisées), gemmothérapie (bourgeons de plantes), élixirs
                floraux (Fleurs du Dr Bach, élixirs contemporains), homéopathie.
              </SecondListElement>
              <SecondListElement>
                <Strong>Micronutrition</Strong> : vitamines, minéraux, acides
                gras oméga 3-6-9, oligo-éléments, probiotiques, compléments
                alimentaires adaptés, associations synergiques.
              </SecondListElement>
            </SecondList>
          </SectionLast>
          <SectionCondition>
            <TextBodyItalic>
              *La consultation de naturopathie ne se substitue en aucun cas à un
              suivi médical, et aucun diagnostic n’est posé.
            </TextBodyItalic>
            <TextBodyItalic>
              **Naturopathie et médecine allopathique sont complémentaires. Les
              recommandations du naturopathe ne remplacent en aucun cas celles
              de votre médecin traitant.
            </TextBodyItalic>
          </SectionCondition>
        </Main>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 290px);
  display: flex;
  justify-content: center;
  padding: 140px 50px 50px 50px;
  @media (max-width: 600px) {
    padding: 130px 50px 50px 50px;
  }
  @media (max-width: 400px) {
    padding: 120px 25px 25px 25px;
  }
`;

const Main = styled.div`
  max-width: 1250px;
  width: 100%;
`;

const Section = styled.div`
  margin: 0px 0px 50px 0px;
`;

const SectionLast = styled.div`
  margin: 0px 0px 30px 0px;
`;

const SectionCondition = styled.div``;

const Title = styled.h2`
  color: ${COLORS.green};
  margin-top: 0px;
`;

const MainList = styled.ol`
  @media (max-width: 650px) {
    padding: 0px 0px 0px 15px;
  }
`;

const MainListElement = styled.li`
  font-weight: 600;
  @media (max-width: 1000px) {
    font-size: 11pt;
  }
`;

const SecondList = styled.ul`
  margin: 0px 0px 16px 0px;
`;

const SecondListElement = styled.li`
  text-align: justify;
  @media (max-width: 1000px) {
    font-size: 11pt;
  }
  @media (max-width: 500px) {
    text-align: left;
  }
`;

const TextBody = styled.p`
  text-align: justify;
  margin: 16px 0px 0px 0px;
  @media (max-width: 1000px) {
    font-size: 11pt;
  }
  @media (max-width: 500px) {
    text-align: left;
  }
`;

const TextBodyItalic = styled.p`
  text-align: justify;
  font-style: italic;
  color: grey;
  font-size: 11pt;
  margin: 0px;
  @media (max-width: 1000px) {
    font-size: 10pt;
  }
  @media (max-width: 500px) {
    text-align: left;
  }
`;

const Stronger = styled.span`
  font-weight: 600;
`;

const Strong = styled.span`
  font-weight: 500;
`;

export default PourquoiConsulter;
