import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import GlobalStyles from "./globalStyles/globalStyles";

import Header from "./components/header";
import Homepage from "./components/homepage";
import Footer from "./components/footer";
import RendezVous from "./components/rendezVous/index";
import Temoignages from "./components/temoignages";
import Assurances from "./components/assurances";
import PourquoiConsulter from "./components/pourquoiConsulter";
import ScrollToTop from "./helpers/scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <GlobalStyles />
        <Header />
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/rendez-vous">
            <RendezVous />
          </Route>
          <Route exact path="/temoignages">
            <Temoignages />
          </Route>
          <Route exact path="/assurances">
            <Assurances />
          </Route>
          <Route path="/pourquoi-consulter">
            <PourquoiConsulter />
          </Route>
        </Switch>
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
