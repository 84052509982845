import React from "react";
import styled from "styled-components";

import COLORS from "../../globalStyles/constants";
import GoogleMap from "./googleMap/index";

const RendezVousPage = () => {
  return (
    <>
      <div>
        <Wrapper>
          <DescriptionWrapper>
            <ContactInfoTitle>Informations</ContactInfoTitle>
            <div>
              <ContactInfoDescription>
                Naturopathe, Iridologue, Conseillère Phytothérapie et
                Aromathérapie. Reconnue Membre A de l’APTN Suisse – Association
                des Praticiens en Thérapies Naturelles.
              </ContactInfoDescription>
              <ContactInfoDescription>
                Je vous reçois à Lausanne - Pully pour des consultations de
                naturopathie, d’iridologie, bilan de vitalité, coaching santé ou
                minceur, mais également pour des massages bien-être, pratique
                l'EFT Emotional Freedom Technique. Vous bénéficiez d’un
                accompagnement physique, psychologique et émotionnel.
              </ContactInfoDescription>
            </div>
          </DescriptionWrapper>

          <ScheduleWrapper>
            <ContactInfoTitle>Horaires</ContactInfoTitle>
            <ScheduleDayWrapper>
              <ScheduleDay>Lundi*</ScheduleDay>
              <ScheduleHour>18h - 19h30</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Mardi*</ScheduleDay>
              <ScheduleHour>18h - 19h30</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Mercredi*</ScheduleDay>
              <ScheduleHour>18h - 19h30</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Jeudi*</ScheduleDay>
              <ScheduleHour>18h - 19h30</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Vendredi*</ScheduleDay>
              <ScheduleHour>18h - 19h30</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Samedi</ScheduleDay>
              <ScheduleHour>8h45 - 12h15</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDay>Dimanche</ScheduleDay>
              <ScheduleHour>Fermé</ScheduleHour>
            </ScheduleDayWrapper>
            <ScheduleDayWrapper>
              <ScheduleDayAsteryx>
                *Seulement disponible en visio-consultation
              </ScheduleDayAsteryx>
            </ScheduleDayWrapper>
          </ScheduleWrapper>

          <DetailsWrapper>
            <ContactInfoTitle>Details</ContactInfoTitle>
            <InfoWrapper>
              <InfoTitle>Adresse:</InfoTitle>
              <InfoDetails>
                Avenue Général-Guisan 44
                <br />
                1er etage
                <br />
                Cabinet plurithérapeutes
                <br />
                1009, Pully
              </InfoDetails>
              <InfoTitle>Email:</InfoTitle>
              <InfoDetails>sophie.tranchida@gmail.com</InfoDetails>
              <InfoTitle>Phone:</InfoTitle>
              <InfoDetails>+41 79 269 88 27</InfoDetails>
            </InfoWrapper>
          </DetailsWrapper>
        </Wrapper>
        <GoogleMapWrapper>
          <GoogleMap />
        </GoogleMapWrapper>
      </div>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: 1400px) {
    grid-template-columns: 300px;
    position: inherit;
    top: 0px;
  }
  @media (max-width: 1050px) {
    grid-template-columns: 1fr 200px 50px 300px 1fr;
    grid-column-gap: 0px;
  }
  @media (max-width: 648px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
  }
  @media (max-width: 400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const DescriptionWrapper = styled.div`
  grid-column: 1 / 3;
  @media (max-width: 1400px) {
    grid-column: 1 /2;
  }
  @media (max-width: 1050px) {
    grid-column: 1 / 6;
  }
  @media (max-width: 648px) {
    grid-column: 1 / 4;
  }
`;
const ScheduleWrapper = styled.div`
  @media (max-width: 1050px) {
    grid-column: 2 / 3;
  }
  @media (max-width: 648px) {
    grid-column: 2 / 3;
    max-width: 300px;
  }
  @media (max-width: 400px) {
    grid-column: 2 / 3;
  }
  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

const DetailsWrapper = styled.div`
  @media (max-width: 1050px) {
    grid-column: 4 / 5;
  }
  @media (max-width: 648px) {
    grid-column: 2 / 3;
    max-width: 300px;
  }
  @media (max-width: 400px) {
    grid-column: 2 / 3;
  }
  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

const ContactInfoTitle = styled.h2`
  margin-top: 0px;
  color: ${COLORS.green};
  @media (max-width: 648px) {
    text-align: center;
  }
`;

const ContactInfoDescription = styled.div`
  text-align: justify;
  font-size: 11pt;
  text-indent: 35px;
`;

const ScheduleDayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11pt;
`;

const ScheduleDay = styled.div`
  padding: 2px 0px;
`;

const ScheduleHour = styled.div`
  padding: 2px 0px;
`;

const ScheduleDayAsteryx = styled.div`
  padding: 2px 0px;
  font-size: 9pt;
  font-style: italic;
  color: grey;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  font-size: 11pt;
  justify-content: start;
  @media (max-width: 1400px) {
    grid-template-columns: 80px 1fr;
  }
`;

const InfoTitle = styled.div`
  padding-bottom: 10px;
  font-weight: bold;
  padding-right: 10px;
  text-align: left;
  float: left;
  justify-content: left;
`;

const InfoDetails = styled.div`
  text-align: left;
  padding-bottom: 10px;
  @media (max-width: 350px) {
    font-size: 9pt;
  }
`;

const GoogleMapWrapper = styled.div`
  width: 550px;
  height: 400px;
  object-fit: contain;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  z-index: -1;
  @media (max-width: 1400px) {
    width: 300px;
    height: 250px;
  }
  @media (max-width: 1050px) {
    margin: 25px auto 0px auto;
    width: 400px;
  }
  @media (max-width: 648px) {
    width: 300px;
  }
  @media (max-width: 350px) {
    width: calc(100vw - 50px);
  }
`;

export default RendezVousPage;
